import { Car } from "../models/cars.model";
import * as _ from "lodash";

function getSelfDriveCars(): Array<Car> {
    const cars = [
        //Kudal HUB Cars
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R (CNG)",
            "Charges": [2000],
            "HalfDayCharges": [1500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [12],
            "Image": "wagonR_new.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "23.56 to 34.05"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga (CNG)",
            "Charges": [3500],
            "HalfDayCharges": [2500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["200"],
            "ExtraKMCharges": [16],
            "Image": "ertiga_new.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 7,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova",
            "Charges": [4000],
            "HalfDayCharges": [3000],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["200"],
            "ExtraKMCharges": [18],
            "Image": "innova.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        },
        {
            "Brand": "Mahindra",
            "Name": "Thar Roxx",
            "Charges": [6000],
            "HalfDayCharges": [4800],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [18],
            "Image": "thar_roxx.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 5,
                "LuggageCapacity": 6,
                "Transmission": "Manual",
                "Avg/Ltr": "12.4 to 15.2"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova Crysta",
            "Charges": [5500],
            "HalfDayCharges": [4500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [18],
            "Image": "crysta.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12 to 15"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Glanza",
            "Charges": [2500],
            "HalfDayCharges": [2000],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [14],
            "Image": "glanza.webp",
            "Details": {
                "Fuel": "Petrol ",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "22.3"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ciaz",
            "Charges": [3000],
            "HalfDayCharges": [2500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi",
                "Sawantwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [15],
            "Image": "ciaz.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 5,
                "LuggageCapacity": 5,
                "Transmission": "Manual",
                "Avg/Ltr": "28"
            }
        },
        //Navi Mumbai HUB Cars
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R (CNG)",
            "Charges": [2200],
            "HalfDayCharges": [2200],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [14],
            "Image": "wagonR_new.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "23.56 to 34.05"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Celerio (CNG)",
            "Charges": [2200],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [0],
            "Image": "celerio.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20 to 28.50"
            }
        },
        {
            "Brand": "Hyundai",
            "Name": "Aura (CNG)",
            "Charges": [2500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [16],
            "Image": "aura.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "22.41 to 31.12"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga (CNG)",
            "Charges": [3500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [18],
            "Image": "ertiga_new.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 7,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "XL6 (CNG)",
            "Charges": [3500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Mulund, Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [18],
            "Image": "xl6.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 6,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "XL6 (CNG)",
            "Charges": [3500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Boriwali, Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [18],
            "Image": "xl6.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 6,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Dzire (CNG)",
            "Charges": [2500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai",
                "Thane",
                "Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [16],
            "Image": "dzire.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "22.41 to 31.12"
            }
        },
        //Ratnagiri HUB Cars
        {
            "Brand": "Hyundai",
            "Name": "XCent (CNG)",
            "Charges": [2000],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["100"],
            "ExtraKMCharges": [16],
            "Image": "xcent.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "17.36 to 25.4"
            }
        },
        {
            "Brand": "Hyundai",
            "Name": "XCent (CNG)",
            "Charges": [2500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["250"],
            "ExtraKMCharges": [16],
            "Image": "xcent.webp",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "17.36 to 25.4"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Dzire",
            "Charges": [2000],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["100"],
            "ExtraKMCharges": [16],
            "Image": "dzire.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "28.4"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Dzire",
            "Charges": [2500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["250"],
            "ExtraKMCharges": [16],
            "Image": "dzire.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "28.4"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": [2299],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["100"],
            "ExtraKMCharges": [16],
            "Image": "ertiga_new.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "25.47"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": [2899],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["250"],
            "ExtraKMCharges": [16],
            "Image": "ertiga_new.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "25.47"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova",
            "Charges": [3500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Ratnagiri"
            ],
            "Hub": "Ratnagiri, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["200"],
            "ExtraKMCharges": [18],
            "Image": "innova.webp",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        }
    ]
    return _.sortBy(cars, ['Charges']);
}
export default getSelfDriveCars;