import { useEffect } from "react"
import { SuperSEO } from "react-super-seo";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SuperSEO
                title="Aapli Gaadi Services: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ backgroundImage: `url('image/aapli_gaadi_locations.png')` }} data-stellar-background-ratio="0.5">
                <div className="overlay">
                </div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs"><span className="mr-2">
                                <a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span>
                                <span>About <i className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-3 bread playwrite-cu-font">About Us</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info">
                        <div>
                            <h3>Who We Are</h3>
                            <p>
                                <span>
                                    Welcome to Aapli Gaadi, your trusted car rental partner in Maharashtra! Whether you need a self-drive car, a chauffeur-driven ride, airport pick and drop, or a reliable driver service, we’ve got you covered.
                                    We operate across <b>Mumbai, Navi Mumbai, Thane, Kudal, Ratnagiri, Kankavli, Vaibhavwadi, Sawantwadi, Malvan, and Tarkarli</b>, ensuring seamless travel experiences tailored to your needs.
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Our Mission</h3>
                            <p>
                                <span>
                                    At Aapli Gaadi, we believe in making car rentals <b>affordable, convenient, and hassle-free</b>.
                                    Our goal is to provide <b>well-maintained vehicles, transparent pricing, and exceptional customer service</b>, making every journey smooth and enjoyable.
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Our Services</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>Self-Drive Car Rentals</b> – Enjoy the freedom of driving at your own pace with our well-maintained fleet.</li>
                                        <li><b>Chauffeur-Driven Cars</b> – Relax and travel stress-free with our professional drivers.</li>
                                        <li><b>Pick & Drop Services</b> – Reliable and punctual rides to airports, bus stands, and other destinations.</li>
                                        <li><b>Driver On-Demand</b> – Need a driver for your car? We provide trained and verified professionals.</li>
                                        <li><b>Car Subscriptions</b> – Long-term car rentals for ultimate convenience.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Why Choose Us?</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>✅ Affordable & Transparent Pricing </b> – No hidden charges, just fair rates.</li>
                                        <li><b>✅ Wide Range of Vehicles </b> – From hatchbacks to SUVs, we have options for every need.</li>
                                        <li><b>✅ Easy Booking Process </b> – Quick online reservations and doorstep delivery.</li>
                                        <li><b>✅ Local Expertise </b> – We know Maharashtra inside out, ensuring a smooth ride every time.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Book Your Ride Today!</h3>
                            <span>
                                Whether you’re planning a weekend getaway, a business trip, or just need a car for daily commutes, <b>Aapli Gaadi</b> is here to help. Experience reliable car rentals with just a few clicks!
                            </span>
                            <br />
                            <br />
                            <p><i>🚗 Drive with Aapli Gaadi – Your Journey, Your Way!</i></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs