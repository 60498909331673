import { useEffect } from "react";
import { SuperSEO } from "react-super-seo";

function PaymentOptions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SuperSEO
                title="Aapli Gaadi Services: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ backgroundImage: `url('image/aapli_gaadi_about.png')` }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Payment <i className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-3 bread playwrite-cu-font">Payment Options</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info">
                        <div className="col-md-12">
                            <p>We offer multiple payment options for your convenience. Choose the method that best suits you and complete your transaction securely.</p>
                            <br />
                        </div>
                        <div className="col-md-6" style={{ padding: '10px' }}>
                            <h3>UPI Payment</h3>
                            <span>You can make payments using UPI by either entering our UPI ID or scanning the QR code.</span>
                            <br />
                            <br />
                            <span><b>UPI ID:</b> crewtechsolutions@upi</span>
                            <br />
                            <span><b>Scan and Pay:</b>
                                <br />
                                <img width={'250px'} src={`${process.env.PUBLIC_URL}/image/qr_bank.png`} alt="crewtechsolutions@upi" className="img img-responsive img-thumbnail" />
                            </span>
                        </div>
                        <div className="col-md-6" style={{ padding: '10px' }}>
                            <h3>Bank Transfer</h3>
                            <span>You can also transfer the amount directly to our bank account. Below are the details:</span>
                            <br />
                            <br />
                            <span>
                                <ul>
                                    <li><b>Bank Name:</b> GP Parsik Bank</li>
                                    <li><b>Account Holder Name:</b> Crewtech Solutions</li>
                                    <li><b>Account Number:</b> 015011300005186</li>
                                    <li><b>IFSC Code:</b> PJSB0000016</li>
                                    <li><b>Account Type:</b> Current</li>
                                </ul>
                            </span>
                        </div>
                        <div className="col-md-12">
                            <br />
                            <span>Once the payment is made, please share the transaction details with us for confirmation.</span>
                            <span>
                                For any payment-related queries, contact our support team at <b><a href="mailto:crewtechsolution@gmail.com">crewtechsolution@gmail.com</a></b> or call us at <b><a href="tel:+917208388380">+91 720 838 8380</a></b>.
                                <br />
                                <br />

                                <i>Thank you for choosing Aapli Gaadi!</i>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PaymentOptions;