import { useEffect } from "react";
import { SuperSEO } from "react-super-seo";

function FAQs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SuperSEO
                title="Aapli Gaadi Services: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ backgroundImage: `url('image/faq_banner.png')` }} data-stellar-background-ratio="0.5">
                <div className="overlay">
                </div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs"><span className="mr-2">
                                <a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span>
                                <span>FAQs <i className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-3 bread playwrite-cu-font">FAQs</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info">
                        <div>
                            <h3>General Questions</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>What is Aapli Gaadi?</b> – Aapli Gaadi is a car rental service provider offering self-drive cars, chauffeur-driven rides, airport pick and drop, and driver services across Maharashtra.</li>
                                        <li><b>Where are your locations?</b> – We operate in Mumbai, Navi Mumbai, Thane, Kudal, Ratnagiri, Kankavli, Vaibhavwadi, Sawantwadi, Malvan, and Tarkarli.</li>
                                        <li><b>What services do you offer?</b> – Our services include self-drive car rentals, chauffeur-driven cars, pick and drop services, driver on-demand, and car subscriptions.</li>
                                        <li><b>How can I book a car?</b> – You can book a car through our website by selecting your location, choosing a vehicle, and completing the booking process online.</li>
                                        <li><b>Do you offer corporate or long-term rentals?</b> – Yes, we provide customized rental solutions for businesses and long-term customers. Contact us for special offers.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Self-Drive Car Rentals</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>What documents are required for self-drive rentals?</b><br />
                                            You need to provide a valid driving license, Aadhaar card or passport for identity verification, and a refundable security deposit.</li>
                                        <li><b>Is there a minimum age requirement for self-drive rentals?</b><br />
                                            Yes, you must be at least 21 years old and have a valid driving license with at least one year of driving experience.</li>
                                        <li><b>Are there any restrictions on where I can take the car?</b><br />
                                            You can drive within Maharashtra. If you plan to travel outside, please inform us in advance to arrange necessary permits.</li>
                                        <li><b>Do I need to refuel the car before returning it?</b><br />
                                            Yes, the car should be returned with the same fuel level as when you received it. Otherwise, fuel charges will be deducted from the security deposit.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Car Rentals with Driver</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>How does the "Car with Driver" service work?</b><br />
                                            You can hire a car with a professional driver for outstation trips, city rides, or local travel. Our drivers are experienced and well-trained.</li>
                                        <li><b>Are driver charges included in the rental price?</b><br />
                                            No, driver charges are separate and will be mentioned at the time of booking.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Pick & Drop Services</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>Do you provide airport pick-and-drop services?</b><br />
                                            Yes, we offer airport transfers from Sindhudurga, Goa, and nearby locations.</li>
                                        <li><b>Can I schedule a pickup in advance?</b><br />
                                            Yes, you can schedule your ride in advance through our website.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Pricing & Payments</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>How are rental charges calculated?</b><br />
                                            Charges are based on the type of vehicle, rental duration, and additional services. Prices are displayed at the time of booking.</li>
                                        <li><b> What payment methods do you accept?</b><br />
                                            We accept online payments via UPI and Bank Transfers.</li>
                                        <li><b> Is there a security deposit for self-drive rentals?</b><br />
                                            Yes, a refundable security deposit is required. The amount varies based on the vehicle category.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Cancellation & Refunds</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>Can I cancel my booking?</b><br />
                                            Yes, you can cancel your booking before the trip starts. Cancellation charges may apply as per our policy.</li>
                                        <li><b>When will I receive my refund?</b><br />
                                            Refunds for cancellations and security deposits (if applicable) are processed within 3-5 working days.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                        <div>
                            <h3>Insurance & Safety</h3>
                            <p>
                                <span>
                                    <ul>
                                        <li><b>Are the rental cars insured?</b><br />
                                            Yes, all our cars are covered under basic insurance. However, any damages caused by the renter are chargeable as per the rental agreement.</li>
                                        <li><b>What should I do in case of an accident or breakdown?</b><br />
                                            In case of an emergency, contact our support team immediately. We will assist you with roadside help and necessary procedures.</li>
                                    </ul>
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FAQs;