import React from "react"
import { Link } from "react-router-dom";

function WebHeader() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={`${process.env.PUBLIC_URL}/image/aapligaadi-dark.png`} height={'60px'} />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                    aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="oi oi-menu"></span> Menu
                </button>

                <div className="collapse navbar-collapse" id="ftco-nav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/terms-and-conditions">Terms & Conditions</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/payment-options">Payment Options</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/book-now">Book Now</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/join-us">Join Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default WebHeader;