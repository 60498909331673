import { useEffect } from "react"
import { SuperSEO } from "react-super-seo";

function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SuperSEO
                title="Aapli Gaadi Services: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ backgroundImage: `url('image/aapli_gaadi_terms.png')` }} data-stellar-background-ratio="0.5">
                <div className="overlay">
                </div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs"><span className="mr-2">
                                <a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span>
                                <span>Terms <i className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-3 bread playwrite-cu-font">Terms and Conditions</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info">
                        <p>
                            <ol>
                                <li>
                                    Introduction
                                    <span>Welcome to Aapli Gaadi! These Terms and Conditions outline the rules and regulations for using our car rental services, including self-drive, chauffeur-driven, pick and drop, and driver services. By accessing or using our website and services, you agree to comply with these terms.</span>
                                </li>
                                <li>
                                    Eligibility
                                    <span>To use our services, you must be at least 21 years old and possess a valid driving license (for self-drive services). Users must provide accurate and complete information during booking</span>
                                </li>
                                <li>
                                    Booking and Payment
                                    <span>
                                        <ul>
                                            <li>All bookings must be made through our official website or authorized platforms.</li>
                                            <li>Payment can be made via credit/debit cards, UPI, or other accepted payment methods.</li>
                                            <li>A security deposit may be required for self-drive rentals.</li>
                                            <li>Cancellations and refunds are subject to our cancellation policy.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Vehicle Usage and Responsibilities
                                    <span>
                                        <ul>
                                            <li>The rented vehicle must be used only for lawful purposes.</li>
                                            <li>Users are responsible for any damages, traffic violations, or fines incurred during the rental period.</li>
                                            <li>Smoking, drinking, and illegal activities inside the vehicle are strictly prohibited.</li>
                                            <li>Fuel policy: Vehicles must be returned with the agreed-upon fuel level.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Insurance and Liability
                                    <span>
                                        <ul>
                                            <li>Any damages to the car in the rented period will be the responsibility of the Users</li>
                                            <li>If the repair cost is up to ₹15,000, users must bear the expense. However, if the cost exceeds ₹15,000, an insurance claim will be processed, and users will be responsible for paying the depreciation charges.</li>
                                            <li>Users will be liable to pay the rent for the number of days required for the repair.</li>
                                            <li>Aapli Gaadi is not liable for personal belongings lost or stolen during the rental period.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Pick and Drop Services
                                    <span>
                                        <ul>
                                            <li>Users must provide accurate pick-up and drop-off locations and times.</li>
                                            <li>Delays or changes to the schedule must be communicated in advance.</li>
                                            <li>Users must be available at the agreed location and time for pickup.</li>
                                            <li>Additional charges may apply for waiting time or changes to the itinerary.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Driver Services
                                    <span>
                                        <ul>
                                            <li>Our drivers are trained and verified professionals.</li>
                                            <li>Users must treat drivers with respect and follow standard safety guidelines.</li>
                                            <li>Any misconduct or complaints should be reported to customer support.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Cancellation and Refund Policy
                                    <span>
                                        <ul>
                                            <li>Cancellations made before 72 hours of booking are eligible for a 90% refund.</li>
                                            <li>Cancellations made within 72 hours and 24 horus of booking are eligible for a 75% refund.</li>
                                            <li>No refund will be done for the cancellations made less than 24 hours before the booking.</li>
                                            <li>Refunds will be processed within 7-10 business days.</li>
                                        </ul>
                                    </span>
                                </li>
                                <li>
                                    Privacy Policy
                                    <span>We value your privacy and handle personal data per our Privacy Policy. By using our services, you consent to the collection and use of your information as described.</span>
                                </li>
                                <li>
                                    Changes to Terms and Conditions
                                    <span>Aapli Gaadi reserves the right to update or modify these terms at any time. Continued use of our services after changes indicates acceptance of the revised terms.</span>
                                </li>
                                <li>
                                    Contact Information
                                    <span>For any questions, concerns, or disputes regarding these terms, please contact us at:
                                        <ul>
                                            <li>Email: <a href="mailto:crewtechsolution@gmail.com">crewtechsolution@gmail.com</a></li>
                                            <li>Phone: <a href="tel:+917208388380">+91 720 838 8380</a></li>
                                        </ul>
                                    </span>
                                </li>
                            </ol>
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms