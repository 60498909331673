import Collapsible from 'react-collapsible';
import * as charges from '../config/charges.json';
import moment from 'moment';
import { useState } from 'react';
import { Car } from '../models/cars.model';

interface CarDetailsProps {
    idx: number,
    vehicle: Car,
    pickupDate: Date,
    dropoffDate: Date,
    selectedPickup: string,
    selectedDropoff: string,
    isSelfDrive: Boolean
}
function CarDetails(props: CarDetailsProps) {
    const [selectedKMs, setSelectedKMs] = useState<string>(props.vehicle.KMIncluded![0]);

    const convertTo24HourFormat = (time: any) => {
        // Extract the hour and period (AM/PM)
        let [hour, period] = time.split(' ');
        hour = parseInt(hour);

        if (period === 'AM') {
            if (hour === 12) {
                // 12 AM is 0 in 24-hour format
                return 0;
            }
            return hour; // AM hours stay the same, except 12 AM
        } else if (period === 'PM') {
            if (hour === 12) {
                // 12 PM is 24 in 24-hour format
                return 24;
            }
            return hour + 12; // Add 12 to PM hours except 12 PM
        }
    }
    const getDays = () => {
        if (props.isSelfDrive) {
            const startHour = convertTo24HourFormat(props.vehicle.StartTime); // 9 AM
            const msInADay = 24 * 60 * 60 * 1000; // milliseconds in a day

            // Helper function to calculate the hours difference
            const getHoursDifference = (date1: Date, date2: Date): number => {
                return Math.abs(date1.getTime() - date2.getTime()) / (1000 * 60 * 60); // Milliseconds to hours
            }

            // Adjust start date for time before 9 AM
            let adjustedStartDate = new Date(props.pickupDate);
            let startDayFraction = 0;

            if (props.pickupDate.getHours() < startHour) {
                // Calculate time difference between start time and 9 AM
                const nineAMStartDate = new Date(props.pickupDate);
                nineAMStartDate.setHours(startHour, 0, 0, 0); // Set to 9 AM on the start date
                const hoursBefore9AM = getHoursDifference(props.pickupDate, nineAMStartDate);

                // If the time before 9 AM is <= 12 hours, it's 0.5 days, otherwise it's 1 day
                startDayFraction = props.vehicle.HalfDayCharges.length > 0 && hoursBefore9AM <= 12 ? 0.5 : 1;

                // Adjust the start date to 9 AM on the previous day
                //adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
                adjustedStartDate.setHours(startHour, 0, 0, 0);
            } else {
                // No need to adjust if the time is after 9 AM, set the time to 9 AM
                adjustedStartDate.setHours(startHour, 0, 0, 0);
            }

            // Adjust end date for time after 9 AM
            let adjustedEndDate = new Date(props.dropoffDate);
            let endDayFraction = 0;

            if (props.dropoffDate.getHours() > startHour) {
                // Calculate time difference between 9 AM and end time
                const nineAMEndDate = new Date(props.dropoffDate);
                nineAMEndDate.setHours(startHour, 0, 0, 0); // Set to 9 AM on the end date
                const hoursAfter9AM = getHoursDifference(nineAMEndDate, props.dropoffDate);

                // If the time after 9 AM is <= 12 hours, it's 0.5 days, otherwise it's 1 day
                endDayFraction = props.vehicle.HalfDayCharges.length > 0 && hoursAfter9AM <= 12 ? 0.5 : 1;

                // Adjust the end date to 9 AM the next day
                adjustedEndDate.setHours(startHour, 0, 0, 0);
                //adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
            } else {
                // No need to adjust if the time is before 9 AM, set the time to 9 AM
                adjustedEndDate.setHours(startHour, 0, 0, 0);
            }

            // Calculate full days (from 9 AM to 9 AM) between the adjusted dates
            const diffInMs = adjustedEndDate.getTime() - adjustedStartDate.getTime();
            const fullDays = Math.floor(diffInMs / msInADay);

            // Return the total days (full days + any fractions calculated)
            const totalDays = fullDays + startDayFraction + endDayFraction;
            return totalDays < 1 ? 1 : totalDays;
        } else {
            if (props.dropoffDate.toLocaleDateString() == props.pickupDate.toLocaleDateString())
                return 1
            const diffTime = Math.abs(props.dropoffDate.getTime() - props.pickupDate.getTime());
            const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return days
        }
    }
    const calculateCharges = () => {
        const pricingIndex = props.vehicle.KMIncluded.indexOf(selectedKMs),
            splitDays = getDays().toString().split('.'),
            days = parseInt(splitDays[0]);

        return days * props.vehicle.Charges[pricingIndex] + (splitDays.length > 1 ? props.vehicle.HalfDayCharges[pricingIndex] : 0);
    }
    const getVehicleCharges = () => {
        if (!selectedKMs) {
            setSelectedKMs(props.vehicle.KMIncluded![0]);
        }
        let selectedIndex = props.vehicle.KMIncluded.indexOf(selectedKMs);
        return {
            charges: props.vehicle.Charges[selectedIndex],
            kmPerDay: selectedKMs ?? 150,
            extraKMCharges: props.vehicle.ExtraKMCharges[selectedIndex]
        }
    }
    const getTotal = () => {
        let total = 0,
            pickUpCharges = props.selectedPickup ? charges[props.selectedPickup as keyof typeof charges]['Convenience Fee'] : 0,
            dropOffCharges = props.selectedDropoff ? charges[props.selectedDropoff as keyof typeof charges]['Convenience Fee'] : 0,
            platformCharges = (props.selectedPickup && props.selectedDropoff) ? charges[props.selectedPickup as keyof typeof charges]['Platform Charges'] + charges[props.selectedDropoff as keyof typeof charges]['Platform Charges'] : 0;

        total = props.isSelfDrive ? (calculateCharges()) + pickUpCharges + dropOffCharges + platformCharges : (calculateCharges()) + platformCharges + getOvernightCharges();
        return total;
    }
    const isOvernightChargeApplicable = () => {
        return !props.isSelfDrive && getDays() > 1;
    }
    const getOvernightCharges = () => {
        if (isOvernightChargeApplicable()) {
            return props.selectedPickup ? charges[props.selectedPickup as keyof typeof charges]['Overnight Charges'] * (getDays() - 1) : 0;
        }
        return 0;
    }
    const getPickupCharges = () => {
        return props.selectedPickup && charges[props.selectedPickup as keyof typeof charges]['Convenience Fee'];
    }
    const getDropoffCharges = () => {
        return props.selectedDropoff && charges[props.selectedDropoff as keyof typeof charges]['Convenience Fee'];
    }
    const getMessage = () => {
        if (props.pickupDate && props.dropoffDate && props.selectedPickup && props.selectedDropoff) {
            let message = `
Hello AapliGaadi,
I am intrested in ${props.vehicle.Brand}, ${props.vehicle.Name}.\n
Booking request details:\n
Start Date & Location: ${moment(props.pickupDate).format('LLLL')} (${props.selectedPickup})
End Date & Location: ${moment(props.dropoffDate).format('LLLL')} (${props.selectedDropoff})\n
Total Days: ${getDays()}
Total KM(s) included: ${getVehicleCharges().kmPerDay.toString() == 'Unlimited' ? 'Unlimited' : parseInt(getVehicleCharges().kmPerDay) * getDays()} KMs
Fleet Charges: ${calculateCharges()}/- Rs.
Pickup Charges: ${getPickupCharges()}/- Rs.
Dropoff Charges: ${getDropoffCharges()}/- Rs.
Platform Charges: ${charges[props.selectedPickup as keyof typeof charges]['Platform Charges'] + charges[props.selectedDropoff as keyof typeof charges]['Platform Charges']}/- Rs.\n
Total Payable Amount: ${getTotal()}/- Rs. \n
Please Note: Additional Refundable Security Deposit will be charged!
`;

            return encodeURIComponent(message);
        }
        return null;
    }
    return (
        <div className="car-wrap rounded" key={props.idx}><div className="row">
            <div className="col-md-4" style={{ padding: '2%', alignContent: 'center' }}>
                <img className='img' style={{ width: '100%', height: 'auto' }} src={`${process.env.PUBLIC_URL}/image/cars/${props.vehicle.Image}`} />
                <p style={{ textAlign: 'center' }}>
                    <img style={{ height: '25px' }} src="/icons/location.png" />
                    &nbsp;&nbsp;{props.vehicle.Hub}&nbsp;&nbsp;
                </p>
            </div>
            <div className="col-md-8" style={{ padding: '2%' }}>
                <div className='text'>
                    <h2 className="mb-0">{props.vehicle.Brand}, {props.vehicle.Name}</h2>
                    {props.vehicle.Charges.length > 1 && (<div>
                        Select daily KMs between {props.vehicle.KMIncluded[0]} and {props.vehicle.KMIncluded[props.vehicle.KMIncluded.length - 1]}
                        <input
                            className='form-control'
                            type='range'
                            min={props.vehicle.KMIncluded[0]}
                            max={props.vehicle.KMIncluded[props.vehicle.KMIncluded.length - 1]}
                            step={50}
                            value={selectedKMs}
                            onChange={(e) => { setSelectedKMs(e.target.value) }}
                        />
                    </div>)}
                    <div className="row">
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/fuel.svg" />
                            &nbsp;&nbsp;{props.vehicle.Details.Fuel}&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/transmission.png" />
                            &nbsp;&nbsp;{props.vehicle.Details.Transmission}&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/luggage.svg" />
                            &nbsp;&nbsp;{props.vehicle.Details.LuggageCapacity} bags&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/seat.svg" />
                            &nbsp;&nbsp;{props.vehicle.Details.SeatingCapacity} Seater&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/milage.png" />
                            &nbsp;&nbsp;{props.vehicle.Details['Avg/Ltr']} kmpl&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/price.png" />
                            &nbsp;&nbsp;<b><u style={{ borderBottom: '1px dotted #000', textDecoration: 'none' }}>{getVehicleCharges().charges}</u></b>/ {props.vehicle.UOM}&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/road.png" />
                            &nbsp;&nbsp;<b><u style={{ borderBottom: '1px dotted #000', textDecoration: 'none' }}>{getVehicleCharges().kmPerDay}</u></b> KMs/Day&nbsp;&nbsp;
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                            <img style={{ height: '30px' }} src="/icons/extra.png" />
                            &nbsp;&nbsp;{getVehicleCharges().extraKMCharges > 0 ? <b><u style={{ borderBottom: '1px dotted #000', textDecoration: 'none' }}>{getVehicleCharges().extraKMCharges} Rs. /KM</u> </b> : 'N/A'} &nbsp;&nbsp;
                        </div>
                        {
                            props.isSelfDrive && <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                <img style={{ height: '30px' }} src="/icons/clock9.svg" />
                                &nbsp;&nbsp;From {props.vehicle["StartTime"]} to {props.vehicle["EndTime"]} &nbsp;&nbsp;
                            </div>
                        }
                        {/* <div className='col-md-12 col-sm-12 col-xs-12' style={{ padding: '5px' }}>
                            <br />
                            <input type="checkbox" id="terms" name="terms" value="terms" /> I agree to the Terms & Conditons
                        </div> */}
                        <br />
                    </div>

                    <p className="d-flex mb-0 d-block">
                        <a href="#trip_details" className="py-2 mr-1">{props.pickupDate && props.dropoffDate && props.selectedPickup && props.selectedDropoff ? `Total: ${getTotal()}/- Rs.` : 'Please select pick up and drop location to proceed with the booking.'}</a>
                        {props.pickupDate && props.dropoffDate && props.selectedPickup && props.selectedDropoff && <>
                            <a href={`https://wa.me/7208388380?text=${getMessage()}`} target="_blank" className="btn btn-primary py-2 ml-1">Book now</a>
                        </>}
                    </p>
                    {props.pickupDate && props.dropoffDate && props.selectedPickup && props.selectedDropoff && <div className='"d-flex'>
                        <Collapsible trigger="View Price Breakup">
                            <div>
                                <ul>
                                    <li>Number of Days: {getDays()} Day(s) </li>
                                    <li>Total KMs Included: {`${getVehicleCharges().kmPerDay.toString() == 'Unlimited' ? 'Unlimited' : parseInt(getVehicleCharges().kmPerDay) * getDays()} KMs`} </li>
                                    <li>Fleet Charges: {calculateCharges()} Rs.</li>
                                    {props.isSelfDrive && <li>
                                        Location Convenience Charges
                                        <ul>
                                            <li>Pick up charges : {getPickupCharges()} Rs.</li>
                                            <li>Drop off charges : {getDropoffCharges()} Rs.</li>
                                        </ul>
                                    </li>}
                                    {isOvernightChargeApplicable() && <li>Overnight charges : {getOvernightCharges()} Rs.</li>}
                                    <li>
                                        Platform Handling Fees: {props.selectedPickup && props.selectedDropoff && charges[props.selectedPickup as keyof typeof charges]['Platform Charges'] + charges[props.selectedDropoff as keyof typeof charges]['Platform Charges']} Rs.
                                    </li>
                                </ul>
                            </div>
                        </Collapsible>
                    </div>}
                </div>
            </div>
        </div>
        </div>
    )
}

export default CarDetails;